














import { Component, Vue } from "vue-property-decorator";
import AuthService from "@/service/authService";
const auth = new AuthService();
@Component
export default class UserNotFound extends Vue {
  public SignIn() {
    this.$router.push({
      name: "Login",
    });
  }
}
